import store from 'store';
import { ACTIVITY_TYPES, DATATABLE_TYPE } from 'state-domains/constants';
import { getPreferences } from 'src/utilities';
import { DropdownItem } from 'src/components/DropdownSelect/DropdownSelect.types';

export const CACHED_PAGES_STORE = 'cached_last_page';

export interface CachedPage {
    offset: number;
    limit: number;
}

export const LISTING_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const LISTING_DEFAULT_LIMIT = LISTING_PAGE_SIZE_OPTIONS[1];
export const LISTING_PAGE_SIZE_DROPDOWN_ITEMS: DropdownItem[] = LISTING_PAGE_SIZE_OPTIONS.map(
    (option: number) =>
        ({
            id: option,
            name: String(option),
        }) as DropdownItem,
);
export const getListingDefaultLimit = (currentUserId: string) =>
    // future proofing in case the values in preferences are not in the current options
    LISTING_PAGE_SIZE_OPTIONS.find((l) => l === getPreferences(currentUserId)?.rowsPerPage) ??
    LISTING_DEFAULT_LIMIT;

export function getDefaultPageCache(userId: string) {
    return {
        offset: 0,
        limit: getListingDefaultLimit(userId),
    };
}

function getCachedPageForComponent(
    component: DATATABLE_TYPE,
    userId: string,
    subscriptionId: string,
) {
    const lastPages = store.get(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
    if (!!lastPages && !!component && lastPages[component]) {
        return lastPages[component];
    }
    return null;
}

export function getCachedPage(component: DATATABLE_TYPE, userId: string, subscriptionId: string) {
    const cached = getCachedPageForComponent(component, userId, subscriptionId);
    const defaults = getDefaultPageCache(userId);
    return checkAndCorrectValues(cached, defaults);
}

export function updateCachedPage(
    component: DATATABLE_TYPE,
    userId: string,
    subscriptionId: string,
    currentOffset: number,
    currentPageSize: number,
) {
    const lastPages = store.get(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
    const updated = {
        ...lastPages,
        [component]: { offset: currentOffset, limit: currentPageSize },
    };
    store.set(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, { ...updated });
}

export function clearAllCachedPages(userId: string, subscriptionId: string) {
    store.set(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
}

export function getCachedCollarPage(
    activityType: ACTIVITY_TYPES,
    userId: string,
    subscriptionId: string,
    projectId: string,
): CachedPage {
    const component =
        activityType === ACTIVITY_TYPES.DRILLING
            ? DATATABLE_TYPE.DRILL_HOLES
            : DATATABLE_TYPE.POINTS;
    const cachedPageObj = getCachedPageForComponent(component, userId, subscriptionId);
    if (cachedPageObj) {
        const pageForProject = cachedPageObj[projectId];
        const defaults = getDefaultPageCache(userId);
        return checkAndCorrectValues(pageForProject, defaults);
    }
    return getDefaultPageCache(userId);
}

export function updateCachedCollarPage(
    activityType: ACTIVITY_TYPES,
    userId: string,
    subscriptionId: string,
    projectId: string,
    currentOffset: number,
    currentPageSize: number,
) {
    const component =
        activityType === ACTIVITY_TYPES.DRILLING
            ? DATATABLE_TYPE.DRILL_HOLES
            : DATATABLE_TYPE.POINTS;
    const lastPages = store.get(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, {});
    const pagesForComponent = lastPages[component] ?? {};
    const updated = {
        ...lastPages,
        [component]: {
            ...pagesForComponent,
            [projectId]: { offset: currentOffset, limit: currentPageSize },
        },
    };
    store.set(`${CACHED_PAGES_STORE}_${userId}_${subscriptionId}`, { ...updated });
}

export function getCachedDataTablePage(
    type: DATATABLE_TYPE,
    userId: string,
    subscriptionId: string,
): CachedPage {
    const component = type;
    const defaults = getDefaultPageCache(userId);
    if (!component) {
        return defaults; // dont need this if check for component in getCachedPageForComponent function
    }
    const cached = getCachedPageForComponent(component, userId, subscriptionId);
    return checkAndCorrectValues(cached, defaults);
}

export function updateCachedDataTablePage(
    type: DATATABLE_TYPE,
    userId: string,
    subscriptionId: string,
    currentOffset: number,
    currentPageSize: number,
) {
    updateCachedPage(type, userId, subscriptionId, currentOffset, currentPageSize);
}

/**
 * Checks if values from local storage are modified. Reset to default values if changed.
 * @param valueFromStorage cached object
 * @param defaults default values
 * @returns value from cache, corrected to defaults if modified
 */
function checkAndCorrectValues(
    valueFromStorage: { offset: number; limit: number } | null,
    defaults: { offset: number; limit: number },
) {
    if (!valueFromStorage) {
        return defaults;
    }
    const cachedPage = { ...valueFromStorage };
    const { offset, limit } = cachedPage;
    if (!LISTING_PAGE_SIZE_OPTIONS.includes(limit)) {
        cachedPage.limit = defaults.limit;
    }
    if (offset % limit !== 0) {
        cachedPage.offset = defaults.offset;
    }
    return cachedPage;
}
